import React from "react"
import Layout from "../../components/layout"
import PostEntry from "../../components/post-entry"
import SEO from "../../components/seo"
import Section from '../../components/Interface/Section'

const UserArchive = ({
  pageContext: { name, posts }
}) => {

  return (
    <Layout>
      <SEO
        title={`User - ${name}`}
        description={`A collection of posts from the ${name} category.`}
      />
      <Section>
        <h1>
          User Archive: <span>{name}</span>
        </h1>
        {
          posts.nodes &&
          posts.nodes.map(post => <PostEntry key={post.id} post={post} />)
        }
      </Section>
    </Layout>
  )
}

export default UserArchive
